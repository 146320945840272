import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box, Spinner } from '@chakra-ui/react';
import Navigation from './components/Navigation';
import { AuthProvider, useAuth } from './services/auth';  // Added useAuth import
import ErrorBoundary from './components/ErrorBoundary';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const Signup = lazy(() => import('./pages/Signup'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const AgentSpecific = lazy(() => import('./pages/AgentSpecific'));
const UserProfile = lazy(() => import('./pages/UserProfile'));

const LoadingSpinner = () => (
  <Box display="flex" justifyContent="center" alignItems="center" minH="100vh">
    <Spinner size="xl" />
  </Box>
);

// Move PrivateRoute component inside App to access useAuth
function App() {
  const PrivateRoute = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
      return <LoadingSpinner />;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  return (
    <ErrorBoundary>
      <AuthProvider>
        <Box>
          <Navigation />
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route 
                path="/" 
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/agent/:id" 
                element={
                  <PrivateRoute>
                    <AgentSpecific />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/profile" 
                element={
                  <PrivateRoute>
                    <UserProfile />
                  </PrivateRoute>
                } 
              />
            </Routes>
          </Suspense>
        </Box>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;