import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Link, Button } from '@chakra-ui/react';
import { useAuth } from '../services/auth';

const Navigation = () => {
  const { isAuthenticated, logout } = useAuth();

  return (
    <Box as="nav" bg="gray.100" py={4}>
      <Flex maxW="1200px" mx="auto" alignItems="center" justifyContent="space-between">
        <Flex>
          <Link as={RouterLink} to="/" mr={4}>Agent List</Link>
          {isAuthenticated && (
            <Link as={RouterLink} to="/profile" mr={4}>User Profile</Link>
          )}
        </Flex>
        <Box>
          {isAuthenticated ? (
            <Button onClick={logout}>Logout</Button>
          ) : (
            <Link as={RouterLink} to="/login">Login</Link>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default Navigation;