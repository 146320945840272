import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';

// Configure Amplify
Amplify.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_ZZ7KIcFFj',
    userPoolWebClientId: '7qlbjda3i0qprm7igckv2t6gmn',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);